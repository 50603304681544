#ErrorButton .error {
    position: absolute;
    top: -120px;
    left: calc(100% - 10px);
    transform: translateX(-100%);
    border-radius: 7px;
    min-width: 300px;
    max-width: 400px;
    height: 100px;
    display: flex;
    align-items: center;
    transition: 0.4s ease-in-out;
    z-index: 30;
    animation: ErrorVisibility 7s ease-in-out;
}

#ErrorButton{
    display: flex;
    text-align: left;
    margin: 0;
    padding: 0;
    outline: none;
    background: none;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}

#ErrorButton .fail {
    box-shadow: 3px 3px 0 #bd790d;
    background: #fca311;   
}

#ErrorButton .fail p {
    color: #000;
}

#ErrorButton .success {
    box-shadow: 3px 3px 0 #2c6e49;
    background: #4c956c;   
}

#ErrorButton .success p{
    color: #fff;
}

#ErrorButton img {
    margin: 5px 10px;
    width: 40px;
    height: 40px;
}

#ErrorButton p{
    padding: 5px 10px 5px 5px;
    font-size: 1em;
}
/* 
.visible{
    top: 10px;
    animation: show 1.5s;
}

.hidden {
    top: -120px;
    animation: show 1.5s;
    animation-direction: reverse;
} */

@keyframes ErrorVisibility {
    0% {
       top: -120px; 
    }

    15% {
        top: 50px;
    }

    85% {
        top: 50px;
    }

    100%{
        top: -120px;
    }

}