.button {
  height: 45px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 14px;
  border-radius: 500px;
  overflow: hidden;
  transition: 0.4s;
}

.button span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}
