.card {
  background: #fff;
  display: flex;
  min-width: min-content;
  height: auto;
  padding: 10px;
  align-items: center;
  border-radius: 11px;
  border: 1px solid #c3c3c3;
  box-shadow: 3px 3px 6px #c3c3c3;
  cursor: pointer;
}

.BackTitle{
  display: none;
}

.iconHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  fill: #888888;
}

.iconHolder > * {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.sepLine {
  width: 1px;
  height: 80px;
  background: #c3c3c3;
  margin: 0 13px 0 8px;
}

.fontSize{
  font-size: 2em;
}

@media screen and (max-width: 1199px) {
  .card {
    display: flex;
    min-width: min-content;
    height: auto;
    padding: 5px;
    align-items: center;
    border-radius: 11px;
    border: 1px solid #c3c3c3;
    box-shadow: 3px 3px 6px #c3c3c3;
    cursor: pointer;
  }

  .fontSize{
    font-size: 1.5em;
  }
}

@media screen and (max-width: 600px) {
  .card {
    display: flex;
    /* width: 100%; */
    height: auto;
    padding: 5px;
    align-items: center;
    border-radius: 11px;
    border: 1px solid #c3c3c3;
    box-shadow: 3px 3px 6px #c3c3c3;
    cursor: pointer;
  }

  .fontSize{
    font-size: 1.5em;
  }
}
