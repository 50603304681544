.imgBox {
  /* box-shadow: 5px 5px 10px #a7a7a7; */
  width: max-content;
  height: 40vh;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.imgBox > *{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
  scale: 1;
  transition: 0.3s;
}

.imgBox:hover > *{
    filter: grayscale(1);
    scale: 1.2;
    transition: 0.3s;
}


.delBtn{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 45px;
  height: 45px;
  background: #f00;
  z-index: 2;
  padding: 5px;
  background: rgb(239, 0, 0);
  border-radius: 5px;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition: 0.3s;
}

.delBtn:hover{
  filter: grayscale(0);
  background: rgb(170, 0, 0);
  transition: 0.3s;
}

.delBtn > *{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
}


@media only screen and (max-width: 992px) {

  .imgBox {
    height: 35vh;
  }

}

@media only screen and (max-width: 758px) {

  .imgBox {
    height: 30vh;
  }

}

@media only screen and (max-width: 600px) {

  .imgBox {
    height: 25vh;
  }

}