.container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.input{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    outline: none;
}

.controls{
    display: flex;
    gap: 5px;
}

@media only screen and (max-width: 600px) {
    .container{
        width: 90%;
    }
    
}