.holder {
  display: flex;
  align-items: center;
  position: relative;
  margin: 15px;
}

.icon {
  position: absolute;
  left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
}

.icon > * {
    width: 100%;
    height: 100%;
}

.input {
  width: 100%;
  height: 45px;
  line-height: 30px;
  padding: 0 10px;
  padding-left: 3rem;
  border-radius: 10px;
  outline: none;
  color: #242424;
  transition: 0.3s ease;
  background-color: #f8fafc;
  border: 2px solid transparent;
}

.invalid{
    width: 100%;
    height: 45px;
    line-height: 30px;
    padding: 0 10px;
    padding-left: 3rem;
    border-radius: 10px;
    outline: none;
    color: #242424;
    transition: 0.3s ease;
    background-color: #f8fafc;
    border: 2px solid #f00;
    box-shadow: 0 0 0 5px #ff000030;
}

.input::placeholder {
  color: #94a3b8;
}

.input:focus,
input:hover {
  outline: none;
  border-color: rgb(151, 151, 151);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgba(55, 55, 55, 0.3);
}
