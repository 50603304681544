.navContainer {
  height: auto;
}

.pages > *:hover,
.smallBtn:hover,
.socialBtns > *:hover {
  background: var(--second-color);
}

.loginBtn {
  all: unset;
  background: var(--first-color);
  color: var(--second-color);
  padding: 10px 15px;
  border-radius: 25px;
  text-align: center;
  font-size: 1em;
  font-weight: normal;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.loginBtn:hover {
  background: var(--second-color);
  color: var(--first-color);
}

@media only screen and (min-width: 769px) {
  /* landscape table */

  .smallBtn {
    display: none;
  }

  .menuContainer {
    padding: 0 5px;
    display: grid;
    grid-template-columns: 50% min-content;
    justify-content: space-evenly;
    width: 100%;
  }

  .pages {
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(3, min-content);
    column-gap: 10px;
  }

  .pages > * {
    all: unset;
    padding: 10px 15px;
    width: min-content;
    font-size: 1em;
    text-align: center;
    text-transform: capitalize;
    border-radius: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .socialBtns {
    width: min-content;
    padding: 5px;
    height: 45px;
    display: grid;
    grid-template-columns: repeat(5, min-content);
    align-items: center;
    column-gap: 10px;
  }

  .socialBtns > * {
    all: unset;
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .socialBtns > * > * {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .loginBtn {
    all: unset;
    background: var(--first-color);
    color: var(--second-color);
    padding: 10px 15px;
    border-radius: 25px;
    text-align: center;
    font-size: 1em;
    font-weight: normal;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .loginBtn:hover {
    background: var(--second-color);
    color: var(--first-color);
  }
}

@media screen and (max-width: 768px) {
  /* phones */

  .smallBtn {
    all: unset;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 10px;
    z-index: 51;
  }

  .smallBtn > * {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .menuContainer {
    position:absolute;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 70vh calc(30vh - 100px);
    align-content: space-evenly;
    width: 100%;
    background: #fffffff5;
    z-index: 50;
    transition: 0.3s;
    overflow: hidden;
  }
  
  .openMenu{
    height: 100vh;
  }

  .closeMenu{
    height: 0;
  }

  .pages {
    padding: 5px;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 60px;
    gap: 3px;
    align-content: center;
    justify-content: start;
    justify-items: start;
  }

  .pages > * {
    all: unset;
    padding: 0 10px;
    width: calc(100% - 20px);
    font-size: 2.3em;
    text-align: left;
    font-weight: lighter;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .socialBtns {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-auto-rows: 45px;
    justify-items: center;
    align-content: center;
    row-gap: 10px;
    padding: 5px;
  }

  .socialBtns > * {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .socialBtns > * > * {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .loginBtn {
    all: unset;
    grid-column: 2/4;
    position: relative;
    text-align: center;
    cursor: pointer;
    background: var(--first-color);
    color: var(--second-color);
    padding: 5px 10px;
    width: 80%;
    border-radius: 25px;
    text-align: center;
    font-size: 1.1em;
    font-weight: lighter;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }

  .loginBtn:hover {
    background: var(--second-color);
    color: var(--first-color);
  }
}
