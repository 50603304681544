.plan {
  width: 100%;
  height: 80vh;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-template-rows: 80vh;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.titlesContainer {
  position: relative;
  margin: 20px 0;
  height: auto;
}

.title {
  text-align: center;
  font-size: 2em;
}

.backTitle {
  width: calc(100% - 3px);
  position: absolute;
  text-align: center;
  top: 3px;
  left: 3px;
  color: #fff;
  font-size: 2em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #d2d2d2;
  z-index: -1;
}

.planImg {
  border-radius: 20px;
  box-shadow: 10px 10px 20px #ccc;
  width: 100%;
  height: 100%;
}

.planImg > * {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description {
  position: relative;
  padding: 30px;
}

.properties {
  width: 100%;
  padding: 30px;
  max-height: 80vh;
  overflow-y: auto;
}

.properties h3 {
  --myColor: #b0b0b0;
  font-size: 1.2em;
  padding: 5px 0;
  margin-bottom: 5px;
  color: var(--myColor);
  border-bottom: 1px solid var(--myColor);
}

.properties p {
  font-size: 1em;
  margin-bottom: 10px;
}

.description {
  height: auto;
}

.description p {
  max-height: 60vh;
  padding: 5px 0;
  font-size: 1em;
  hyphens: auto;
  text-align: justify;
  overflow-y: auto;
}

@media screen and (max-width: 1199px) {
  .title {
    text-align: center;
    font-size: 1.5em;
  }

  .backTitle {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 3px;
    left: 3px;
    color: #fff;
    font-size: 1.5em;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #d2d2d2;
    z-index: -1;
  }
}

@media screen and (max-width: 992px) {
  .plan {
    grid-template-columns: 25% 35% 35%;
    grid-template-rows: 80vh;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .description {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .planImg {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .properties {
    grid-column: 3/4;
    grid-row: 1/2;
  }
}

@media screen and (max-width: 768px) {
  .plan {
    grid-template-columns: 47% 47%;
    grid-template-rows: 50vh 30vh;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-bottom: 20px;
  }

  .description {
    padding: 0 30px;
    max-height: 50vh;
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .description p {
    max-height: 28vh;
  }

  .planImg {
    width: auto;
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .properties {
    max-height: 30vh;
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

@media screen and (max-width: 600px) {
  .plan {
    height: auto;
    grid-template-columns: 93%;
    grid-auto-rows: 40vh;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .description {
    height: auto;
    grid-column: 1/2;
    grid-row: 2/3;
    padding: 20px 20px;
  }

  .description p {
    max-height: 23vh;
  }

  .planImg {
    margin: 0;
    padding: 0;
    width: 90%;
    height: 40vh;
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .properties {
    padding: 0 30px;
    max-height: 30vh;
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
