.post {
  position: relative;
  width: 70%;
  display: grid;
  grid-template-columns: auto 50%;
  box-shadow: 0 0 15px #999999;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
}

.embed > *{
  height: 100%;
}

.delBtn{
  position: absolute;
  padding: 5px;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  background: rgb(239, 0, 0);
  border-radius: 5px;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition: 0.3s;
}

.delBtn:hover{
  background: rgb(170, 0, 0);
  transition: 0.3s;
}

.delBtn > *{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
}


.postPictures {
  width: 100%;
  height: 45vh;
  display: flex;
  padding: 10px;
  overflow-y: auto;
  gap: 5px;
  scroll-snap-type: x mandatory;
}

.postPictures > * {
    width: 90%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
    scroll-snap-align: center;
}

.postPictures iframe{
    min-width: 80%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
    scroll-snap-align: center;
}

.description {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description p{
  max-height: calc(45vh - 65px);
  text-align: justify;
  hyphens: auto;
  overflow-y: auto;
  line-height: 1.7;
}

.controls {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
}

.icon {
  display: flex;
  align-self: center;
  justify-content: center;
  height: 40px;
}

.iconHolder {
  width: 45px;
  height: 45px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconHolder > * {
  width: 100%;
  height: 100%;
}

.icon span {
  display: flex;
  align-self: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .post {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .post {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .post{
    grid-template-columns: 100%;
    grid-template-rows: 50vh min-content;
  }

  .postPictures {
    height: 50vh;
  }

  .description p{
    max-height: calc(35vh - 65px);
    text-align: justify;
    hyphens: auto;
    overflow-y: auto;
    line-height: 1.7;
  }
}