.titleHolder{
    padding: 10px;
}

.imgContainer{
    width: max-content;
    padding: 20px 15px;
    display: flex;
    gap: 10px;
}

.container{
    width: 100%;
}

.imgBox{
    width: 100%;
    overflow-x: auto;
}