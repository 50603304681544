.imgBox{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.imgBox > *{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}