:root{
  --first-color: #272a2b;
  --second-color: #f3f5f2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 18px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #0f0f0f;
  border: 5px solid #fff;
  border-radius: 9px;
}
