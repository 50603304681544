.container {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
}


.controls{
    padding: 20px;
    position: absolute;
    width: 60%;
    height: calc(100vh - 50px);
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    z-index: 30;
    background: #fff;
}

.contolBtn{
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.button{
    background: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    stroke: #000;
    stroke-width: 2;
    cursor: pointer;
}

.button:hover{
    background: #121212;
    stroke: #fff;
    transition: 0.3s;
}

.imgViwer {
  /* position: absolute;
  top: 50%;
  left: 20px; */
  border-radius: 15px;
  /* transform: translateY(-50%); */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  border: 1px solid #f4f4f4;
  background: #f4f4f4;
  transition: 0.3s;
}

.imgViwer video {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.imgViwer img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.imgLister {
  position: absolute;
  padding: 20px;
  width: calc(40% - 20px);
  height: calc(100vh - 50px);
  right: 0;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-auto-rows: 200px;
  gap: 5px;
  overflow-y: auto;
}

@media only screen and (max-width: 1200px) {
  .imgLister {
    grid-template-columns: 50% 50%;
    grid-auto-rows: 200px;
  }
}

@media only screen and (max-width: 992px) {
  .imgLister {
    width: calc(50% - 20px);
    grid-auto-rows: 200px;
  }
}

@media only screen and (max-width: 768px) {
  
  .imgLister {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(45vh - 50px);
    bottom: 0;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-rows: 100px;
  }
}

@media only screen and (max-width: 600px) {
  
  .imgLister {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(45vh - 50px);
    bottom: 0;
    grid-template-columns: 32% 32% 32%;
    grid-auto-rows: 100px;
  }
}