.editBtn {
    position: fixed;
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
    border: none;
    padding: 10px;
    box-shadow: 5px 5px 10px #adadad;
    background: var(--first-color);
    z-index: 49;
}

.editBtn>* {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
}

.editMenu {
    position: fixed;
    background: #fff;
    right: 20px;
    bottom: 90px;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 50px;
    max-width: 600px;
    gap: 10px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #d0d0d0;
    z-index: 49;
}

.controls {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
    border: none;
    padding: 10px;
    background: var(--first-color);
    outline: none;
    cursor: pointer;
}

.controls>img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
}

.controlBtns {
    display: grid;
    grid-template-columns: 50px 50px;
    width: 100%;
    justify-content: space-between;
}

.newStuff {
    position: fixed;
    background: #fff;
    right: 20px;
    bottom: 90px;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    max-width: 600px;
    gap: 10px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #d0d0d0;
    z-index: 49;
}

.editMenu>button {
    -webkit-tap-highlight-color: transparent;
    border: none;
    padding: 10px;
    background: var(--second-color);
    border-radius: 5px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
}

.editMenu>button:hover {
    background: var(--first-color);
    color: #fff;
}

.form input {
    margin: 5px 0;
    width: 100%;
    height: 45px;
    line-height: 30px;
    padding: 0 10px;
    padding-left: 3rem;
    border-radius: 10px;
    outline: none;
    color: #242424;
    transition: 0.3s ease;
    background-color: #f8fafc;
    border: 2px solid transparent;
}

.form .invalid{
    width: 100%;
    height: 45px;
    line-height: 30px;
    padding: 0 10px;
    padding-left: 3rem;
    border-radius: 10px;
    outline: none;
    color: #242424;
    transition: 0.3s ease;
    background-color: #f8fafc;
    border: 2px solid #f00;
    box-shadow: 0 0 0 5px #ff000030;
}

.form input::placeholder {
  color: #94a3b8;
}

.form input:focus,
.form input:hover {
  outline: none;
  border-color: rgb(151, 151, 151);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgba(55, 55, 55, 0.3);
}



@media only screen and (max-width: 600px) {
    .editMenu {
        max-width: 90%;
    }

    .newStuff {
        max-width: 90%;
    }
}