.Projects {
  padding: 20px 0;
  width: 100%;
  height: auto;
}

.titleSize {
  /* width: 100%;
  margin: 0;
  padding: 0; */
  width: calc(100% - 5px);
  font-size: 2.5em;
  height: fit-content;
}

.Cards {
  padding: 20px 30px;
  width: 100%;
  min-height: 300px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;

  /* --s: 100px; /* control the size
  --c1: #ffffff;
  --c2: #d2d2d2;

  --_g: var(--c2) 6% 14%, var(--c1) 16% 24%, var(--c2) 26% 34%,
    var(--c1) 36% 44%, var(--c2) 46% 54%, var(--c1) 56% 64%, var(--c2) 66% 74%,
    var(--c1) 76% 84%, var(--c2) 86% 94%;
  background: radial-gradient(
      100% 100% at 100% 0,
      var(--c1) 4%,
      var(--_g),
      var(--c2) 96%,
      #ffffff00
    ),
    radial-gradient(
        100% 100% at 0 100%,
        #0000,
        var(--c2) 4%,
        var(--_g),
        var(--c1) 96%
      )
      var(--c1);
  background-size: var(--s) var(--s); */

  --ang: 45deg;

  --strip-end: transparent;
  --strip-mid: var(--c0);
  --strip-stop: 10px;
  --strip-f: .4;
  background:
    repeating-linear-gradient(#f00),
    repeating-linear-gradient(60deg, var(#ff0)),
    repeating-linear-gradient(-60deg, var(#ff0));
  --mask:
    conic-gradient(from calc((var(--i) - .75)*180deg),
      transparent, red, transparent 50%) box-shadow: inset 0px 20px 40px #fff,
    inset 0px -20px 40px #fff;
}


@media screen and (max-width: 992px) {
  .titleSize {
    font-size: 2em;
  }
}

@media screen and (max-width: 768px) {
  .titleSize {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 600px) {
  .titleSize {
    font-size: 1.7em;
  }

  .Cards {
    display: grid;
    grid-template-columns: 100%;
  }
}