.header {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: calc(100vh - 120px);
  align-items: center;
  justify-items: center;
}

/* .header > div{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: calc(50% - 30px);
    height: auto;
} */

.title {
  font-size: 4em;
}

.category {
  font-size: 2em;
  font-weight: lighter;
}

.titlesGroup {
  position: relative;
  width: max-content;
}

.imgSelection{
  height: 80vh;
  overflow-y: auto;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-auto-rows: 100px;
  gap: 5px
}

.selectImg{
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.selectImg > *{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.BackTitle {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 5px;
  color: #fff;
  font-size: 4em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #d2d2d2;
  z-index: -1;
}

.titleImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  height: 70vh;
  border-radius: 10px;
  overflow: hidden;
}

.titleImg img {
  border-radius: 20px;
  width: auto;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.upload {
  width: 50%;
}

.upload input {
  margin: 5px 0;
  width: 100%;
  height: 45px;
  line-height: 30px;
  padding: 0 10px;
  padding-left: 3rem;
  border-radius: 10px;
  outline: none;
  color: #242424;
  transition: 0.3s ease;
  background-color: #f8fafc;
  border: 2px solid transparent;
}

.upload .invalid {
  width: 100%;
  height: 45px;
  line-height: 30px;
  padding: 0 10px;
  padding-left: 3rem;
  border-radius: 10px;
  outline: none;
  color: #242424;
  transition: 0.3s ease;
  background-color: #f8fafc;
  border: 2px solid #f00;
  box-shadow: 0 0 0 5px #ff000030;
}

.upload input::placeholder {
  color: #94a3b8;
}

.upload input:focus,
.upload input:hover {
  outline: none;
  border-color: rgb(151, 151, 151);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgba(55, 55, 55, 0.3);
}


@media only screen and (max-width: 992px) {
  .title {
    font-size: 3em;
  }

  .BackTitle {
    position: absolute;
    top: 4px;
    left: 4px;
    color: #fff;
    font-size: 3em;
    -webkit-text-stroke-width: 1px;
    z-index: -1;
  }

  .category {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    grid-template-columns: 40% 60%;
    grid-template-rows: calc(100vh - 120px);
    align-items: center;
    justify-items: center;
  }

  .title {
    font-size: 2em;
  }

  .BackTitle {
    position: absolute;
    top: 3px;
    left: 3px;
    color: #fff;
    font-size: 2em;
    -webkit-text-stroke-width: 1px;
    z-index: -1;
  }

  .category {
    font-size: 1em;
    font-weight: normal;
  }

  .titleImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 10px);
    height: 70vh;
    border-radius: 10px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {

  .upload {
    width: 90%;
  }

  .header {
    grid-template-columns: 100%;
    grid-template-rows: 40vh 40vh;
    align-items: center;
    justify-items: center;
  }

  .title {
    font-size: 2.5em;
  }

  .BackTitle {
    position: absolute;
    top: 3px;
    left: 3px;
    color: #fff;
    font-size: 2.5em;
    -webkit-text-stroke-width: 1px;
    z-index: -1;
  }

  .category {
    font-size: 1.2em;
    font-weight: normal;
  }

  .titleImg {
    height: 40vh;
  }
}